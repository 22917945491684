import './App.css';
import LandingPage from './Pages/LandingPage/landingPage.js'

function Home() {
  return (
    <div className="Home">
      <LandingPage></LandingPage>
    </div>
  );
}



export default Home;